import ExamResults from "../../ExamResults/ExamResults";

const ExamResultsPage = () => {
  return (
    <>
      <ExamResults />
    </>
  );
};

export default ExamResultsPage;

import Collection from "../../Collection/Collection";

const CollectionPage = () => {
  return (
    <>
      <Collection />
    </>
  );
};

export default CollectionPage;

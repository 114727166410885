import Course from "../../Course/Course";

const CoursePage = () => {
  return (
    <>
      <Course />
    </>
  );
};

export default CoursePage;

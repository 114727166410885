import Bank from "../../Bank/Bank";

const BankPage = () => {
  return (
    <>
      <Bank />
    </>
  );
};

export default BankPage;

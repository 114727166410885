import CollectionCatalog from "../../CollectionCatalog/CollectionCatalog";

const CollectionCatalogPage = () => {
  return (
    <>
      <CollectionCatalog />
    </>
  );
};

export default CollectionCatalogPage;

import Exam from "../../Exam/Exam";

const ExamPage = () => {
  return (
    <>
      <Exam />
    </>
  );
};

export default ExamPage;

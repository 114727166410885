import UpdateCollection from "../../CreateCollection/UpdateCollection";

const CreateCollectionPage = () => {
  return (
    <>
      <UpdateCollection />
    </>
  );
};

export default CreateCollectionPage;
